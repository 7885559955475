<template>
  <div id="manageCompany" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Manage Company</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Owner</span>
        </v-btn>
      </div>
    </div>    
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="items">
          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="btn-edit" @click="editItem(item)">
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- dialog สำหรับ เพิ่ม แก้ไข -->
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <!-- set form กรอกข้อมูลที่นี้ -->

              <v-flex xs3>
                <v-text-field v-model="formModel.c_code" label="Company Code"></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="formModel.c_name" label="Company Name"></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="formModel.c_alcohol_limit" label="Alcohol Limit"></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-text-field v-model="formModel.c_temp_limit" type="" label="Temperature Limit"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="saveToServer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ v-if="test()" -->

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation ?</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>Delete {{formModel.c_name}} ?</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";

export default {
  data: () => ({
    headers: [
      { value: "c_code", text: "Company Code", sortable: true },
      { value: "c_name", text: "Company Name", sortable: true },
      { value: "c_alcohol_limit", text: "Alcohol Limit (mg.)", sortable: true },
      { value: "c_temp_limit", text: "Temperature Limit (°C)", sortable: true },
      { value: "action", text: "", sortable: false }
    ],
    defaultValue: {
      c_code:"",
      c_name: "",
      c_alcohol_limit:"",
      c_temp_limit:""
    },
    query: { sort: { c_id: -1 } },
    //--end config

    items: [], // data ที่มาจากการ find ของ server
    inDTO: {}, // data ที่มาจากการ get ของ server
    outDTO: {}, // data ที่เป็น Object ที่จะส่งไป create หรือ update ที่ server
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "" // มีได้ 2 แบบคือ create กับ edit
  }),
  computed: {
    formTitle() {
      return this.mode === "create"
        // ? "Add " + this.objectName
        // : "Edit " + this.objectName;
        ? "Add"
        : "Edit";
    }
  },
  async mounted() {
    this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientUOA.service("company").find({ query: q });
        res.data.forEach(element => {
          element.c_alcohol_limit = element.c_alcohol_limit.toFixed(2);
          element.c_temp_limit = element.c_temp_limit.toFixed(2);
          this.items.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },

    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            c_code: this.formModel.c_code,
            c_name: this.formModel.c_name,
            c_alcohol_limit: parseFloat(this.formModel.c_alcohol_limit).toFixed(2),
            c_temp_limit: parseFloat(this.formModel.c_temp_limit).toFixed(2),
            mb: "Aun2",
            md: new Date()
          };
          await feathersClientUOA
            .service("company")
            .patch(this.formModel.c_id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
          alert("ไม่สามารถแก้ไขข้อมูลได้");
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          // hook ที่จะแก้ข้อมูลก่อนส่งไป server ใส่ที่นี้
          let dataAdd = {
            c_code: this.formModel.c_code,
            c_name: this.formModel.c_name,
            c_alcohol_limit: parseFloat(this.formModel.c_alcohol_limit).toFixed(2),
            c_temp_limit: parseFloat(this.formModel.c_temp_limit).toFixed(2),
            cb: "Aun2",
            cd: new Date()
          };
          await feathersClientUOA.service("company").create(dataAdd);
          this.renderUI();
        } catch (err) {
          console.log(err);
          alert("ไม่สามารถเพิ่มข้อมูลได้" + err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataDelete = {
          mb: "GEM 2",
          md: new Date(),
          retire: 1
        };
        await feathersClientUOA
          .service("company")
          .patch(this.formModel.c_id, dataDelete);
        this.renderUI();
      } catch (err) {
        console.log(err);
        alert("ไม่สามารถลบข้อมูลได้");
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    }
  }
};
</script>